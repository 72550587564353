.remove-btn {
  margin-right: .5rem;
}

.fade-enter {
  opacity: .01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: .01;
  transition: opacity 1000ms ease-in;
}

.header {
  background-color: #b0773f;
  color: #000000;
  text-align: center;
  padding: 10px;
  font-family: Verdana;
  font-size: large;
}

body {
  background: #fafafa;
  
  color : #2b2129;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 .footer {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: #5a6e5a;
   color: #ffffff;
   text-align: center;
   font-family: Verdana;
 }

 .map {
  height: 100vh;
}

.info {
  padding: 8px 10px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.85);
  box-shadow: 1 1 15px rgba(0,0,0,0.2);
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 25px;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  position: absolute;
  float: right;
}

.legend {
  height: 104px;
  line-height: 18px;
  color: #555;
  text-align: left;
}
.i {
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 8px;
  opacity: 1;
}

.legend .title {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.countryLabel{
  background: rgba(255, 255, 255, 0);
  border:0;
  border-radius:0px;
  box-shadow: 0 0px 0px;
}
